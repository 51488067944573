<template>
  <v-row>
    <v-col cols="12" class="mt-25">
      <div class="d-flex mt-n6">
        <div class="px-0">
          <v-navigation-drawer class="drawer-compliance ma-0 rounded-0 pb-6" permanent app clipped fixed olor="bgSearch" width="300">
            <v-list class="px-8 py-4">
              <v-list-item-content>
                <v-list-item-title class="subtitle-1 font-weight-bold d-inline-block text-truncate" style="max-width: 200px;">Los Portales</v-list-item-title>
              </v-list-item-content>
            </v-list>
            <v-divider />
            <v-list nav dense class="mt-4 px-8" style="overflow-y: scroll; height: calc(90vh - 220px);">
              <v-list-group class="mb-3" v-for="item in list" :key="item.text" v-model="item.active" no-action :ripple="false">
                <template v-slot:activator>
                  <v-list-item-content style="min-width: 160px;">
                    <v-list-item-title class="fontBody--text subtitle-1 font-weight-bold" v-text="item.text" />
                  </v-list-item-content>
                </template>
                <v-list-item class="pa-0" active-class="transparent" :ripple="false">
                  <v-list-item-content>
                    <v-text-field class="mb-2" v-model="search" autocomplete="off" background-color="bgSearch" dense hide-details outlined placeholder="Buscar..." clearable clear-icon="mdi-close" @click:clear="search = ''">
                      <template v-slot:prepend-inner>
                        <span class="ml-2 mr-n1">
                          <v-icon color="defaultGrey">mdi-magnify</v-icon>
                        </span>
                      </template>
                    </v-text-field>
                    <v-list-item-title>
                      <v-col class="px-0 py-1" v-for="child in item.items" :key="child.name">
                        <v-checkbox
                          @click="getCurrent(child.id)"
                          dense
                          color="primary"
                          hide-details
                          :key="child.name"
                        >
                          <template v-slot:label>
                            <span class="body-1">
                              <span class="d-inline-block text-truncate" style="max-width: 190px;">{{child.name}}</span>
                              <span v-if="item.text === 'Empresas'" class="mt-n1 d-block caption text--disabled">
                                <span>RUC</span>
                                {{child.id}}
                              </span>
                            </span>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
            <template v-slot:append>
              <v-col class="text-center px-8 mt-n14">
                <v-btn block color="primary" :ripple="false">Aplicar</v-btn>
                <v-btn class="mt-3" block outlined :ripple="false">Cancelar</v-btn>
              </v-col>
            </template>
          </v-navigation-drawer>
        </div>
        <div style="flex: 1; min-width: 600px;">
          <v-col class="text-end pb-0">
            <v-btn class="mr-5" outlined ><v-icon left size="20">mdi-export-variant</v-icon>Exportar</v-btn>
          </v-col>
          <v-card class="my-5 mx-8" outlined>
            <v-toolbar class="px-3 subtitle-1 fontBody--text" flat height="55">
              <v-icon color="fontBody" left>mdi-chart-timeline-variant</v-icon>
              Resultados del la última semana de envíos
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <v-row no-gutters>
                <!-- status -->
                <v-col :cols="$vuetify.breakpoint.width < 1200 ? 12 : 6" class=" py-5 pl-7">
                  <span class="body-1 font-weight-bold">Documentos enviados entre </span>
                  <span class="d-block fontDraw--text body-2">29 sep. 2022 - 06 oct.2022</span>
                  <div class="d-flex">
                    <v-col cols="3">
                      <v-sheet class="mt-5 transparent" min-width="76">
                        <span class="d-block text-h6">27.804</span>
                        <div class="align-center">
                          <span class="body-2 fontDraw--text d-block">Recibidos</span>
                          <span class="d-block mt-n1 caption-small fontDraw--text">T. envios: 98%</span>
                        </div>
                      </v-sheet>
                    </v-col>
                    <v-col cols="3">
                      <v-sheet class="mt-5 transparent" min-width="82">
                        <span class="d-block text-h6">25.433</span>
                        <div class="align-center">
                          <span class="body-2 fontDraw--text d-block">Abiertos</span>
                          <span class="d-block mt-n1 caption-small success--text">T. apertura: 82%</span>
                        </div>
                      </v-sheet>
                    </v-col>
                    <v-col cols="4">
                      <v-sheet class="mt-5 transparent" min-width="108">
                        <span class="d-block text-h6 error--text">958</span>
                        <div class="align-center">
                          <span class="body-2 fontDraw--text d-block">Rebotados</span>
                          <span class="d-block mt-n1 caption-small error--text">T. no entregados: 6%</span>
                        </div>
                      </v-sheet>
                    </v-col>
                    <v-col cols="2">
                      <v-sheet class="mt-5 transparent" min-width="68">
                        <span class="d-block text-h6 error--text">333</span>
                        <div class="align-center">
                          <span class="body-2 fontDraw--text d-block">SPAM</span>
                          <span class="d-block mt-n1 caption-small error--text">T. SPAM: 2%</span>
                        </div>
                      </v-sheet>
                    </v-col>
                  </div>
                </v-col>
                <!-- end status -->
                <v-col :cols="$vuetify.breakpoint.width < 1200 ? 12 : 6" class="pt-5 py-8 pr-4 pl-8 flex-grow-1 flex-shrink-0">
                  <v-sheet class="mt-4 transparent">
                    <v-row no-gutters>
                      <v-col cols="8" class="mr-8 ml-2 py-0">
                        <chart-line
                          class="mt-n4"
                          axe-type-y="number"
                          :background-color="'transparent'"
                          :chart-data="receivedChartData"
                          chart-id="documentsIssued"
                          :custom-labels="['Enviados', 'Recibidos', 'Abiertos', 'Rebotados']"
                          :height="140"
                          :number-rounded="true"
                          :show-axe-x="false"
                          :show-draw-border-grid-lines-axe-y="false"
                          :show-grid-lines-axe-y="false"
                          :show-point="true"
                          :borderWidth="1"
                          style="position:relative;"
                        />
                      </v-col>
                      <v-col lass="pa-0 mt-0 align-center" style="max-width: 100px;">
                        <v-sheet class="my-4 transparent" height="80">
                          <span class="d-block body-2">
                            <v-avatar class="pb-1 mr-2" size="10" color="primary" />Enviados
                          </span>
                          <span class="d-block body-2">
                            <v-avatar class="pb-1 mr-2" size="10" color="var(--light-blue)" />Recibidos
                          </span>
                          <span class="d-block body-2">
                            <v-avatar class="pb-1 mr-2" size="10" color="var(--light-light-blue-lighten-2)" />Abiertos
                          </span>
                          <span class="d-block body-2">
                            <v-avatar class="mr-2" size="10" color="var(--dm-indigo-light)" />Rebotados
                          </span>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-divider />

              <!-- table -->
              <template>
                <v-data-table class="table-not-rounded" :headers="headers" :items="itemsTable" @click:row="(itemsTable) => { currentItem = itemsTable; modalRedirect = true }" :items-per-page="pagination" item-key="serie" hide-default-header disable-sort mobile-breakpoint="300" hide-default-footer style="cursor: pointer">
                <!-- header -->
                <template v-slot:header="{ props: { headers } }">
                  <VTableHeaders :headers="headers" @sort="setSort" />
                  <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -5px; z-index: 2" />
                </template>
                <!-- end header -->
                <template v-slot:[`item.name`]="{item}">
                  <template v-if="item.name.length">
                    <span class="body-1 font-weight-medium">{{item.name}}</span>
                    <span class="fontDraw--text body-2 d-block" v-if="item.id">RUC {{item.id}}</span>
                    <span class="body-2 text--disabled d-block" v-else>Sin especificar</span>
                  </template>
                  <span class="body-2 text--disabled" v-else>Sin especificar</span>
                </template>
                <template v-slot:[`item.serie`]="{item}">
                  <span class="body-1 font-weight-medium">{{item.serie}}</span>
                </template>
                <template v-slot:[`item.date`]="{item}">
                  <span class="body-1 font-weight-medium">{{item.date}}</span>
                </template>
                <template v-slot:[`item.taxpayer`]="{item}">
                  <template v-if="item.taxpayer.name.length">
                    <span class="body-1 font-weight-medium">{{item.taxpayer.name}}</span>
                    <span class="fontDraw--text body-2 d-block" v-if="item.taxpayer.tax_id">RUC {{item.taxpayer.tax_id}}</span>
                    <span class="body-2 text--disabled d-block" v-else>Sin especificar</span>
                  </template>
                  <span class="body-2 text--disabled" v-else>Sin especificar</span>
                </template>
              </v-data-table>
              <v-divider />
              <template v-if="showPagination || showAll">
                <v-row class="mx-0" align="center">
                  <template v-if="showPagination">
                    <v-col cols="6">
                      <span class="body-1">{{count}} registro{{count > 1 ? 's' : ''}}</span>
                    </v-col>
                    <v-col cols="6" class="text-right mr-auto">
                      <v-pagination v-model="page" :length="totalPages" total-visible="6" />
                    </v-col>
                  </template>
                </v-row>
              </template>
              </template>
              <!-- <v-col class="text-center py-9" v-else>
                <v-icon color="fontBody" size="48">mdi-file-document-remove</v-icon>
                <span class="d-block body-1 font-weight-bold">No existen documentos</span>
              </v-col> -->
              <!-- end table -->
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-col>
    <!-- modal redirect -->
    <v-dialog v-model="modalRedirect" width="420" persistent no-click-animation>
      <VModal @close="modalRedirect = false" :items="currentItem" />
    </v-dialog>
    <!-- end modal redirect -->

     <!-- dialog upload -->
     <v-dialog v-model="handleUpload" width="700" persistent no-click-animation scrollable>
      <v-card flat outlined rounded>
        <v-card-title class="pa-0">
          <v-toolbar class="px-4" color="transparent" flat height="48">
            <v-toolbar-title class="body-1 secondary--text font-weight-bold">Subir archivo</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pa-0 pb-8">
          <v-list class="px-4 transparent">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="ml-n2">
                  <v-file-input type="file" accept=".xml" prepend="mdi-paper-clip" background-color="transparent" hide-details placeholder="No se eligió ningún archivo" solo flat chips />
                </v-list-item-title>
                <v-list-item-subtitle class="pt-3 text--primary ml-1">Haz click para seleccionar un archivo o arrastra el archivo aquí (xml, xlsx, xlsm, xlsb)</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="text-right py-2 px-7">
          <v-spacer />
          <v-btn @click="handleUpload=false" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="uploadFile" :loading="loading" color="primary">Cargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog upload -->
  </v-row>
</template>
<script>
import ChartLine from '@/components/charts/ChartLine'
import documentListPE from '@/data/documentsList' // example
import ListViewMixin from '@/mixins/ListViewMixin'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VModal from '@/modules/mail-tracker/components/VModal'
import ChartDoughnut from '@/components/charts/ChartDoughnut'

export default {
  components: {
    VTableHeaders,
    VModal,
    ChartLine,
    ChartDoughnut
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    loading: false,
    handleUpload: false,
    showTable: false,
    currentItem: {},
    modalRedirect: false,
    modalErrorInf: false,
    count: 10,
    selectedItem: 0,
    search: '',
    receivedChartData: {
      labels: [
        "Lun",
        "Mar",
        "Mie",
        "Jue",
        "Vie",
        "Sab",
        "Dom"
      ],
      datasets: [
        {
          label: "Enviados",
          backgroundColor: '#455A64',
          percent_change: '20.84',
          data: [60, 33, 56, 10, 100, 50, 30]
        },
        {
          label: "Recibidos",
          backgroundColor: '#2466CB',
          percent_change: '70.20',
          data: [90, 53, 22, 50, 60, 70, 80]
        },
        {
          label: "Abiertos",
          backgroundColor: '#2196F3',
          percent_change: '10.84',
          data: [30, 63, 96, 60, 80, 30, 20]
        },
        {
          label: "Rebotados",
          backgroundColor: '#BE2D2F',
          percent_change: '-0.34',
          data: [5, 15, 2, 30, 20, 10, 15]
        }
      ]
    },
    list: [
      {
        text: 'Empresas',
        disabled: true,
        active: false,
        items: [
          {
            name: 'Los Portales Estacionamientos Operadora S.A.',
            id: '20603381697'
          },
          {
            name: 'Los Portales S.A',
            id: '20301837896'
          },
          {
            name: 'Concesión Estacionamientos Miraflores S.A',
            id: '20549412263'
          },
          {
            name: 'Los Portales Estacionamientos S.A.',
            id: '20603381824'
          }
        ]
      },
      {
        text: 'Tipos de documentos',
        disabled: true,
        active: false,
        items: [
          {
            name: 'Boleta electrónica',
            id: '01'
          },
          {
            name: 'Factura electrónica',
            id: '02'
          },
          {
            name: 'Nota de crédito',
            id: '03'
          },
          {
            name: 'Nota de debito',
            id: '04'
          },
          {
            name: 'Comprobante de retención',
            id: '05'
          },
          {
            name: 'Comprobante de percepción',
            id: '06'
          },
          {
            name: 'D.autorizado electrónico',
            id: '07'
          },
          {
            name: 'Guías de Remisión',
            id: '08'
          }
        ]
      },
      {
        text: 'Estado',
        disabled: true,
        active: false,
        items: [
          {
            name: 'Recibidos',
            id: '01'
          },
          {
            name: 'Abiertos',
            id: '02'
          },
          {
            name: 'Rebotados',
            id: '03'
          },
          {
            name: 'SPAM',
            id: '04'
          }
        ]
      }
    ],
    chartData: {
      labels: ['En cumplimiento', 'Con problemas', 'No consultados'],
      datasets: [
        {
          data: [85, 15, 0]
        }
      ]
    },
    chartDataEmpty: {
      labels: ['En cumplimiento', 'Con problemas', 'No consultados'],
      datasets: [
        {
          data: [100]
        }
      ]
    },
    chartData1Empty: {
      labels: ['POS', 'B.O.', 'CD', 'E-Com'],
      datasets: [
        {
          data: [100]
        }
      ]
    },
    chartData1: {
      labels: ['POS', 'B.O.', 'CD', 'E-Com'],
      datasets: [
        {
          data: [25, 25, 25, 25]
        }
      ]
    },
    itemsTable: [
      {
        name: 'Los Portales Estacionamientos Operadora S.A.',
        id: '20603381697',
        document_type: 'Boleta electrónica',
        serie: 'B001-00000001',
        origen: 'POS',
        date: '16 ene. 2023',
        status: 'Enviado',
        taxpayer: {
          name: 'Consumidor Final',
          tax_id: '77777777777'
        }
      },
      {
        name: 'Los Portales S.A',
        id: '20301837896',
        document_type: 'Boleta electrónica',
        serie: 'B001-00000002',
        origen: 'B.O.',
        date: '16 ene. 2023',
        status: 'Recibido',
        taxpayer: {
          name: 'Consumidor Final',
          tax_id: '77777777777'
        }
      },
      {
        name: 'Concesión Estacionamientos Miraflores S.A',
        id: '20549412263',
        document_type: 'Boleta electrónica',
        serie: 'B001-00000003',
        origen: 'CD',
        date: '16 ene. 2023',
        status: 'Abierto',
        taxpayer: {
          name: 'Consumidor Final',
          tax_id: '77777777777'
        }
      },
      {
        name: 'Los Portales Estacionamientos S.A.',
        id: '20603381824',
        document_type: 'Factura electrónica',
        serie: 'F001-00000001',
        origen: 'B.O.',
        date: '16 ene. 2023',
        status: 'Abierto',
        taxpayer: {
          name: 'Consumidor Final',
          tax_id: '77777777777'
        }
      },
      {
        name: 'Los Portales Estacionamientos S.A.',
        id: '20603381824',
        document_type: 'Factura electrónica',
        serie: 'F001-00000002',
        origen: 'POS',
        date: '16 ene. 2023',
        status: 'Recibido',
        taxpayer: {
          name: 'Consumidor Final',
          tax_id: '77777777777'
        }
      },
      {
        name: 'Los Portales S.A',
        id: '20301837896',
        document_type: 'Boleta electrónica',
        serie: 'B001-00000005',
        origen: 'B.O.',
        date: '16 ene. 2023',
        status: 'Rebotado',
        taxpayer: {
          name: 'Consumidor Final',
          tax_id: '77777777777'
        }
      },
      {
        name: 'Los Portales Estacionamientos Operadora S.A.',
        id: '20603381697',
        document_type: 'Boleta electrónica',
        serie: 'B001-00000006',
        origen: 'POS',
        date: '16 ene. 2023',
        status: 'Abierto',
        taxpayer: {
          name: 'Consumidor Final',
          tax_id: '77777777777'
        }
      },
      {
        name: 'Concesión Estacionamientos Miraflores S.A',
        id: '20549412263',
        document_type: 'Factura electrónica',
        serie: 'F001-00000007',
        origen: 'E-com',
        date: '16 ene. 2023',
        status: 'Rebotado',
        taxpayer: {
          name: 'Consumidor Final',
          tax_id: '77777777777'
        }
      },
      {
        name: 'Los Portales S.A',
        id: '20301837896',
        document_type: 'Boleta electrónica',
        serie: 'B001-00000008',
        origen: 'POS',
        date: '16 ene. 2023',
        status: 'Recibido',
        taxpayer: {
          name: 'Consumidor Final',
          tax_id: '77777777777'
        }
      },
      {
        name: 'Concesión Estacionamientos Miraflores S.A',
        id: '20549412263',
        document_type: 'Factura electrónica',
        serie: 'F001-00000007',
        origen: 'E-com',
        date: '16 ene. 2023',
        status: 'Recibido',
        taxpayer: {
          name: 'Consumidor Final',
          tax_id: '77777777777'
        }
      }
    ],
    headers: [
      { text: 'Emisor', value: 'name', sortable: false },
      { text: 'T. documento', value: 'document_type', sortable: false, width: 158 },
      { text: 'Serie - Correlativo', value: 'serie', align: 'end', sortable: false, width: 194 },
      { text: 'Fecha', value: 'date', align: 'end', sortable: false, width: 110 },
      { text: 'Estado', value: 'status', align: 'center', sortable: false, width: 120 },
      { text: 'Receptor', value: 'taxpayer', sortable: false, width: 190 }
    ],
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  methods: {
    getCurrent () {
      //
    },
    handleModalRedirect () {
      this.modalRedirect = false
      this.$router.push({name: 'DocumentsRetrieve', params: {id: documentListPE.find(({taxpayer}) => taxpayer.tax_id === this.currentItem.id).id || '' }}).catch(() => {})
    },
    handleErrorInf (item = {}) {
      this.modalErrorInf = true
    },
    uploadFile () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.showTable = true
        this.handleUpload = false
      }, 7500)
    }
  }
}
</script>
