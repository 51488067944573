export default [
		{
			amount_tax: null,
			customer_tax_id: "20492092313",
			status: "REJ",
			updated: "2023-12-11T18:11:33.281802240Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145790,
			size: 14352,
			filename: "",
			retries: 0,
			customer_name: "MAKRO SUPERMAYORISTA S.A.",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:30.987731453Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_UuGPiAAAAYxaE7gr",
			agency_status: "2329",
			digest: "fjTcMlEL5upf7FKKYyQ07Q==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145790",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "2264461f-5c8c-4d37-953f-daf267bd689a",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "3155-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:32.707904118Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145796,
			size: 25181,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:30.292839223Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_UqoM9wAAAYxaE7V0",
			agency_status: "2329",
			digest: "4/zse0fDoUBShWg6+y1gwA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145796",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "81c33cf3-9a52-406b-bfec-7d1289596bc9",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "3128-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20100070970",
			status: "REJ",
			updated: "2023-12-11T18:17:39.289651810Z",
			time: "01:16:33",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145808,
			size: 12394,
			filename: "",
			retries: 0,
			customer_name: "SUPERMERCADOS PERUANOS SA",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:17:36.668007829Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_cNCSsgAAAYxaGUyc",
			agency_status: "2329",
			digest: "ZCejQpXkRGqFrG62Xl4HDw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145808",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "724578ad-8e52-4e36-9204-277c5abf0b4a",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "3124-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20492092313",
			status: "REJ",
			updated: "2023-12-11T18:11:35.845953501Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145793,
			size: 13776,
			filename: "",
			retries: 0,
			customer_name: "MAKRO SUPERMAYORISTA S.A.",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:33.542558093Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_bFvToAAAAYxaE8Im",
			agency_status: "2329",
			digest: "aDSjTp2ahnQrwwXi4WleYA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145793",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "5c88915a-e557-4dfd-959e-6cf1b00b413f",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "3117-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20492092313",
			status: "REJ",
			updated: "2023-12-11T18:11:34.562499897Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145798,
			size: 13247,
			filename: "",
			retries: 0,
			customer_name: "MAKRO SUPERMAYORISTA S.A.",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:32.189296016Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_REKsDAAAAYxaE7zd",
			agency_status: "2329",
			digest: "jFM+OOo7B/HqSJ5E0SfxXg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145798",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "9bf94221-b171-4eb0-aa37-bdcb0bfe2586",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "3111-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20492092313",
			status: "REJ",
			updated: "2023-12-11T18:11:34.245253385Z",
			time: "01:11:15",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145806,
			size: 14374,
			filename: "",
			retries: 0,
			customer_name: "MAKRO SUPERMAYORISTA S.A.",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:31.935027950Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_V1IurwAAAYxaE7vf",
			agency_status: "2329",
			digest: "eQfmqWY/cUtPS/pZfDHj6Q==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145806",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "4ecffc10-6fbc-4bfd-82d7-be429b4bd290",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "3103-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:33.611846906Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145797,
			size: 49343,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:31.255558816Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_kunuHgAAAYxaE7k3",
			agency_status: "2329",
			digest: "xAXm+MEplUvtvLwuKaDOOw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145797",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "fd350175-ab9c-486a-84b9-e7e9cab7a2dd",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "3033-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:31.098486935Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145787,
			size: 33218,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:28.830474107Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_gK2IsQAAAYxaE6--",
			agency_status: "2329",
			digest: "1HQ84W9deEIZKo7qQFwuZg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145787",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "0561b310-8c1d-4f64-831d-635848b95891",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2992-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:33.632881510Z",
			time: "01:11:15",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145805,
			size: 21788,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:31.339269404Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_Bsd85wAAAYxaE7mL",
			agency_status: "2329",
			digest: "rpW0kYoJ34jImubSF34jZA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145805",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "7fcc5593-935a-4e5a-97a7-e5e0dc16de16",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2964-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:31.988203429Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145795,
			size: 26915,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:29.560354496Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_71gf4AAAAYxaE7KY",
			agency_status: "2329",
			digest: "eaG2s55k6Y9aPmRdPx269w==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145795",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "18e1cf8f-e9d7-4170-8b9b-76b2dc65405b",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2934-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:30.762697975Z",
			time: "01:11:15",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145801,
			size: 38469,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:28.481216088Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_K_Q9qwAAAYxaE65h",
			agency_status: "2329",
			digest: "mF0HvL7PFfG0nfOP6KzQCw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145801",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "842e7c64-5606-4dc2-86b4-7d3f5d374636",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2879-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:32.113679887Z",
			time: "01:11:15",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145803,
			size: 38304,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:29.838091450Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_RJPz1AAAAYxaE7Ou",
			agency_status: "2329",
			digest: "/43tz8zCfJCgTI2Vcur0TA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145803",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "0ba8e7da-5504-4700-9886-4ee6d80b7ede",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2829-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:35.231001226Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145792,
			size: 78080,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:32.900695929Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_wzdgmgAAAYxaE7-k",
			agency_status: "2329",
			digest: "r8uwVHNzF1+bMtbQJpdc/w==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145792",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "5c3dc825-7c67-412b-a6e6-b238b96cd7b1",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2687-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:31.189724686Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145794,
			size: 51597,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:28.846404047Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_ns5KaQAAAYxaE6_O",
			agency_status: "2329",
			digest: "oOL6vERnhgVqzIyRGhGsSA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145794",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "856c4716-a884-4bb5-ab6b-8790538c6bbe",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2612-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:35.132853837Z",
			time: "01:11:15",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145807,
			size: 35052,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:32.832198684Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_Ce1BQgAAAYxaE79g",
			agency_status: "2329",
			digest: "ohTNcPVfrhRCu1pQwCREVQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145807",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "c6ba7516-c7a8-498e-ad9b-d318cd15c672",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2567-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:35.933983693Z",
			time: "01:11:15",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145800,
			size: 49982,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:33.616189563Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_djoAmwAAAYxaE8Jw",
			agency_status: "2329",
			digest: "tldqNiyxJGA34WX74RPhXg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145800",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "b126fe68-79d9-4308-8752-9055866cb55a",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2489-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:32.639383736Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145789,
			size: 57898,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:30.317766062Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_lwjLkgAAAYxaE7WN",
			agency_status: "2329",
			digest: "dFa1g/F35CotCq27bPJB7w==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145789",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "5b8c2c05-d250-4386-9714-43f93262b189",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2403-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:31.985918314Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145788,
			size: 73981,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:29.517252535Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_wQmUSgAAAYxaE7Jt",
			agency_status: "2329",
			digest: "CsqLRUL7+ZWVbwSxVEEtzw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145788",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "7bf647dc-f613-40fb-bdfb-ed5f4a69c85c",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2282-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "REJ",
			updated: "2023-12-11T18:11:35.239227125Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145799,
			size: 51660,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:32.962661834Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_8HJyNwAAAYxaE7_i",
			agency_status: "2329",
			digest: "aV6XiXQZBwxB/27xSiNK7g==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145799",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "c1f87a9e-17cf-493e-938c-6e1a567ac507",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2208-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:48.039670339Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143423,
			size: 10347,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:45.749778057Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_eOHhKQAAAYwcGsV1",
			agency_status: "2329",
			digest: "IFPugYdIi/GJqkEYWjDi8w==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143423",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "d0fb9512-f140-47f3-8efb-50e3aece6a9d",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2132-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:35.154646009Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143404,
			size: 10922,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:32.879720345Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_7q7hGgAAAYwcGpMv",
			agency_status: "2329",
			digest: "rQ00Qc+rRDbuqLFtujAx/A==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143404",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "ae3c9749-6d4a-4b47-8c50-b079fb27130e",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2130-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20601233488",
			status: "REJ",
			updated: "2023-12-11T18:11:34.500740828Z",
			time: "01:11:14",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145791,
			size: 55105,
			filename: "",
			retries: 0,
			customer_name: "PLAZA VEA ORIENTE S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:32.203387149Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_UHRWNQAAAYxaE7zr",
			agency_status: "2329",
			digest: "CnXaBOgxySmMenJaPpfNpA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145791",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "4160d217-2bec-42c5-8403-e381639c979d",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2129-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:52.204875439Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143419,
			size: 10921,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:49.977888560Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_MUzWPwAAAYwcGtX5",
			agency_status: "2329",
			digest: "ZTienJnQleLz77O5Xj0Dfw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143419",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "12c6b45d-c00f-490f-b4cc-d1e800b98fd9",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2128-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:51.609448918Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143418,
			size: 10930,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:49.348380332Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_5CDcaQAAAYwcGtOE",
			agency_status: "2329",
			digest: "3HL70UkGFWno7zmUwpO66Q==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143418",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "2a6d9dc5-0be6-464d-a70f-35b42574cdaa",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2126-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:31.321936406Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143391,
			size: 10907,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:29.074099034Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_kuuWAQAAAYwcGoRS",
			agency_status: "2329",
			digest: "Nw0w4DJ0jwAc0dUqLOReNg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143391",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "b76c1d55-a8e2-43f9-899f-5c765d65b2d0",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2124-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:47.046420519Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143432,
			size: 11493,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:44.792498990Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_puKs6QAAAYwcGsG4",
			agency_status: "2329",
			digest: "8J608BWC6QZmEXiTDSyIyA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143432",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "1da0d353-accc-4271-a69c-c1ebf23886be",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2121-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:53.054111717Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143420,
			size: 10927,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:50.785117218Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_pqj9nAAAAYwcGtkh",
			agency_status: "2329",
			digest: "xI5qoRiIQYss4LB+ujFfVQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143420",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "729d36f5-d4fb-46d6-82b9-659dc7f748bd",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2119-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:50.430865943Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143416,
			size: 10358,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:48.243241250Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_SlXzxwAAAYwcGs8z",
			agency_status: "2329",
			digest: "F7aajTQi2Xm3l0vWLVoxVg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143416",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "e31f7a13-674c-4042-8340-660633d6811a",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2118-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:51.339935540Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143427,
			size: 10357,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:49.085919867Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_3UIcbwAAAYwcGtJ9",
			agency_status: "2329",
			digest: "lIcjIAPRGm2pWd9l8w9+Wg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143427",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "1e146a39-e81f-475d-8cab-9d47fac8c280",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2117-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:50.983588391Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143417,
			size: 10922,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:48.771955917Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_anpnWAAAAYwcGtFD",
			agency_status: "2329",
			digest: "BIzBmCyc1I4Ur+cMqpz05g==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143417",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "48a82332-020b-402a-b612-3dfccbd4d479",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2115-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:34.243844193Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143403,
			size: 10933,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:32.001700228Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_xoUYPgAAAYwcGo_B",
			agency_status: "2329",
			digest: "7yVN+00g3j8udDtRwIlLHw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143403",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "8975cff6-9477-4e2b-ac21-61d01f41a243",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2113-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:49.887485948Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143415,
			size: 10922,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:47.614129568Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_dfapwgAAAYwcGsy-",
			agency_status: "2329",
			digest: "JH7+wgUx83ayDIFoVMf+lw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143415",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "0278c23d-c243-4464-8671-24e01860f3e6",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2111-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:33.713889183Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143402,
			size: 10945,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:31.433167627Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_-mVchQAAAYwcGo2J",
			agency_status: "2329",
			digest: "dDNOswhnhjOGML+3dv55TQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143402",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "925d309b-ae31-48db-9091-ff92ff6fcf44",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2109-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:34.013806792Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143395,
			size: 10373,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:31.777569155Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_1p_xJwAAAYwcGo7h",
			agency_status: "2329",
			digest: "azKgnGK0ZZFyOKrp4GIgtw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143395",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "5f88d8de-2ca4-4f46-ae40-51d368be020a",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2108-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:31.890660527Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143407,
			size: 12050,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:29.594380504Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_N6sfaQAAAYwcGoZa",
			agency_status: "2329",
			digest: "zbfZclHyVs1bDpsEC344CA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143407",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "67539926-7470-4279-a5f8-7753e25c5039",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2104-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:33.370306704Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143409,
			size: 10348,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:31.103870676Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_NydBbgAAAYwcGow_",
			agency_status: "2329",
			digest: "4cqix1GIicIHEJebyYBPSw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143409",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "12cff8cc-ba88-450e-acd9-3ce259b9c074",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2103-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:51.608241750Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143439,
			size: 10934,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:49.387351595Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_Ts0kgAAAAYwcGtOr",
			agency_status: "2329",
			digest: "NWvBAuuJaDvPlwvXGRYQgQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143439",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "fc47df3d-cbb0-4d2e-944c-59eda8770af6",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2101-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:31.399617790Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143399,
			size: 10932,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:29.170955152Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_S6Hg6gAAAYwcGoSy",
			agency_status: "2329",
			digest: "K6ELtjF11gLxUZ9KUhDvJg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143399",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "dd33c7de-f8e4-449f-adf8-4c0e61feee54",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2099-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:34.540856046Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143411,
			size: 10941,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:32.276325647Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_9th1xgAAAYwcGpDU",
			agency_status: "2329",
			digest: "F40XWUzcOkcGTkZ5NDXuSA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143411",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "33d53d73-c02d-4d44-8da6-0cd96c8f8fe6",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2097-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:32.014443206Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143392,
			size: 10929,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:29.764807397Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_yzma-QAAAYwcGocE",
			agency_status: "2329",
			digest: "r4niLsjQ1lvfLF4BRwbeBg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143392",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "ce2adbdb-1521-42ef-b459-20afbd09296e",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2095-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:31.280774355Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143406,
			size: 10365,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:29.048202973Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_GbzLVwAAAYwcGoQ4",
			agency_status: "2329",
			digest: "HzXkzH2lpMvzAr6KrPLj9w==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143406",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "1ee85b11-af34-4f9c-b1e3-ca2525f6b907",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2094-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:29.982919660Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143389,
			size: 10934,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:27.717709037Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_I6KrkgAAAYwcGn8F",
			agency_status: "2329",
			digest: "h6rs3T6n9yjIH6Gh2e859A==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143389",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "8af5aae3-b1ba-44d1-8646-01a2cc77b36c",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2092-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:33.934413838Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143410,
			size: 10367,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:31.713954772Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_VnGkUAAAAYwcGo6h",
			agency_status: "2329",
			digest: "VpHNsea2gxgnIoF9Ztx+sg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143410",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "befcf454-5281-4af8-98f3-12b29e9232c2",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2091-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:51.021786631Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143438,
			size: 10372,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:48.850734848Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_mrh-pQAAAYwcGtGS",
			agency_status: "2329",
			digest: "InzsSVPfVyuP8F5l4fyyZg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143438",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "3e972ead-4896-4a22-b79d-b37c44be9f67",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2090-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:54.150452523Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143422,
			size: 11486,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:51.954448665Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_9CP3qQAAAYwcGt2y",
			agency_status: "2329",
			digest: "dGg4PBsvaAe6gBa4/aW/ww==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143422",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "3ebd49e5-0669-412d-b637-863a8d7990e2",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2087-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:29.731715920Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143405,
			size: 10927,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:27.455211923Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_E_N_IgAAAYwcGn3_",
			agency_status: "2329",
			digest: "BHeEev/PLgsHdPBLUfjXiw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143405",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "e4d45bb6-aa12-463b-8119-baca2e489727",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2085-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:47.891472545Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143433,
			size: 10929,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:45.678645044Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_z-R6IwAAAYwcGsUu",
			agency_status: "2329",
			digest: "beF3e1T6fWhgcfDFnxEHQQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143433",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "cdc8eac7-26c3-42ee-976c-61aa830f895a",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2083-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:53.629479884Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143421,
			size: 11506,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:51.369822231Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_uSIBTgAAAYwcGttp",
			agency_status: "2329",
			digest: "Wuw7tklnA0pfYsSww0u/Bg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143421",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "3090622b-d5e6-4803-90d4-ec036bdc8255",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2080-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:32.009387317Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143400,
			size: 10925,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:29.766649442Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_bIrV1wAAAYwcGocG",
			agency_status: "2329",
			digest: "1t+J6DvUp+bRFR+F6jbt9w==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143400",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "a4056595-5339-4ebd-a07e-13b99c6adb88",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2078-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20601233488",
			status: "REJ",
			updated: "2023-12-11T18:11:32.947030284Z",
			time: "01:11:15",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 145804,
			size: 40250,
			filename: "",
			retries: 0,
			customer_name: "PLAZA VEA ORIENTE S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-11T18:11:30.676958396Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_cV1G-QAAAYxaE7b0",
			agency_status: "2329",
			digest: "r1CHZhZgto7qIszdq2VUEw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00145804",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "be902f89-40c5-4ac4-8d77-6e486e197d54",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2075-12-11"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:50.494148391Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143437,
			size: 11494,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:48.324287409Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_n1AqFAAAAYwcGs-E",
			agency_status: "2329",
			digest: "KNGG6sVTtqfL9BeDX5eY1w==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143437",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "ffe30a8a-6c0f-4fc5-9a8f-a295b97bc1c6",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2075-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:32.840613820Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143393,
			size: 11487,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:30.622561159Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_WDE0uAAAAYwcGope",
			agency_status: "2329",
			digest: "6GfGJkOs7pABsxnDKgnfEQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143393",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "9cbc0ac8-0c17-4fa2-b705-e75da563adc6",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2072-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:48.939913713Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143414,
			size: 11505,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:46.635523931Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_eisMlwAAAYwcGsjr",
			agency_status: "2329",
			digest: "nPtDS8eLbXaL9ROwM8qp4w==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143414",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "a988d73e-aa33-4e14-ad7c-c8da34888071",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2069-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:48.478777100Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143434,
			size: 10366,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:46.272923714Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_Tl5TFQAAAYwcGseA",
			agency_status: "2329",
			digest: "xM3JZwQMt2GsmJNEc7HH3w==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143434",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "b7e6df13-1a84-41ce-a235-76d25a38d863",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2068-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:30.581610071Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143390,
			size: 10362,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:28.328086258Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_hm99pAAAAYwcGoFo",
			agency_status: "2329",
			digest: "6Jj7kl1WMUw3Sco+SdE7og==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143390",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "e543e889-5b7a-4e33-8f2c-7dbe87975196",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2067-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:50.735739192Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143426,
			size: 10928,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:48.512874771Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_dSyFNwAAAYwcGtBA",
			agency_status: "2329",
			digest: "fh1oWerhZnD7CkWbxzi3jg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143426",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "442fc8c3-6c2d-4494-9997-129261bb44de",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2065-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:51.955226294Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143428,
			size: 11489,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:49.682900450Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_pNMiUQAAAYwcGtTS",
			agency_status: "2329",
			digest: "u9spYp4PVGNchFplFXvvzA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143428",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "ceb360ca-cf80-4e9a-a508-ae4ab23401d4",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2062-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:32.896455735Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143401,
			size: 12051,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:30.624604219Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_JLCjWQAAAYwcGopg",
			agency_status: "2329",
			digest: "rvIQ4buCThL50uuoIPIymg==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143401",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "4127d2a1-cce8-4b6c-87bd-adc3591cf8e4",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2058-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:33.460149069Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143394,
			size: 10905,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:31.206113860Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_TSo4ZQAAAYwcGoym",
			agency_status: "2329",
			digest: "KaO8eE9oW2uCoJT3ifuTcw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143394",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "54e17dfe-cb80-48a0-b89a-f9479b7f276f",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2056-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:49.358110396Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143435,
			size: 10921,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:46.821978223Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_YX7y9QAAAYwcGsml",
			agency_status: "2329",
			digest: "rZAVg9WKaR9HqAu0Vq1QyQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143435",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "84d18af2-44d1-4344-b941-3d92174ee50f",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2054-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:53.027884088Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143430,
			size: 10916,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:50.803039479Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_pioZuQAAAYwcGtkz",
			agency_status: "2329",
			digest: "Bw7R9q9mA20BxN2X6tC0dQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143430",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "4e4d4337-4b55-4e24-ab22-2bcfe3733fe5",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2052-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:52.723530008Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143440,
			size: 10372,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:50.483150299Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_92XcuwAAAYwcGtfz",
			agency_status: "2329",
			digest: "npjAN7IEtCJkwDbf15wpOQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143440",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "245e0866-14ff-45a0-a431-db9196a1342d",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2051-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:50.211416045Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143425,
			size: 10925,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:47.944919851Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_FS5bgQAAAYwcGs4I",
			agency_status: "2329",
			digest: "BHdmsmY/e/JgyAeUFlqpRA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143425",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "88e0bdc1-4fa2-4591-9109-ac905f8917c4",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2049-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:47.996090377Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143413,
			size: 10365,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:45.744155390Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_91bWxwAAAYwcGsVw",
			agency_status: "2329",
			digest: "0i8HIpVx3MGCXQotTksr9Q==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143413",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "c7f6cc69-4ebb-4757-a9a6-cfc5bd7b615c",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2048-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:29.968932300Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143397,
			size: 10348,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:27.714314820Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_JMs6QgAAAYwcGn8C",
			agency_status: "2329",
			digest: "rwkDDTPq+mdy4kkAArJuXQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143397",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "df869393-f22b-4c22-9393-ea04569f9c1b",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2047-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:49.957053611Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143436,
			size: 10935,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:47.714796361Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_dGrtXwAAAYwcGs0i",
			agency_status: "2329",
			digest: "Xjt48Elt2hM1Cj/gwalo9A==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143436",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "85018ad7-ffc6-4e9b-bb2f-8e4f4956f4ad",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2045-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:35.617610911Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143396,
			size: 11483,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:33.327222605Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_RfxAAAAAAYwcGpTv",
			agency_status: "2329",
			digest: "DioJSiSm/ICKCUuQeS/HWQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143396",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "2f7d206c-8070-4aa1-8ada-9ff06fd2fabb",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2042-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:49.576489657Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143424,
			size: 11478,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:47.346985709Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_jjyoHwAAAYwcGsuy",
			agency_status: "2329",
			digest: "uED9fHDKwtgRlzyjnb7FTA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143424",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "dffa2b10-2dd7-4f48-8c8f-f760e2e6ca95",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2039-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:32.586564938Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143408,
			size: 11487,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:30.278347508Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_kqVzWwAAAYwcGokG",
			agency_status: "2329",
			digest: "3b4lss29uNVwELve14Ksdw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143408",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "6666207b-58af-4f50-8eee-8f43dfe4c98a",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2036-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:53.551265052Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143431,
			size: 10940,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:51.374690321Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_N26pIwAAAYwcGttu",
			agency_status: "2329",
			digest: "fIZHcptn1yVA4Wr5wfNQiQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143431",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "190bae46-e3e9-4c21-bbf4-a39ed7f7f0d9",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2034-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:52.427489783Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143429,
			size: 12035,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:50.264252274Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_nbIWbwAAAYwcGtcY",
			agency_status: "2329",
			digest: "/sdRQmZtoFlbdkxX8VvKNQ==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143429",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "7e587375-c49d-4c27-b7ad-507682fb0f27",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2030-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "REJ",
			updated: "2023-11-29T17:22:30.569441877Z",
			time: "12:21:28",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T760",
			serial: 143398,
			size: 10937,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-11-29T17:22:28.309308903Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "La fecha de emision se encuentra fuera del limite permitido",
			email: null,
			id: "do_2bkUgwAAAYwcGoFV",
			agency_status: "2329",
			digest: "OkqYOoACkx2IB76kclacMA==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T760-00143398",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "218c2e91-2c0d-4577-ae0e-14dd9addb8a4",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2028-11-29"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "OBS",
			updated: "2023-12-22T16:33:06.854374222Z",
			time: "11:31:47",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T602",
			serial: 493322,
			size: 10316,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh4GfV2Bv0DL+w5OK2BbvibrZDZCPytuJiLQs4/Akb8i+tO7ruUp57wSMoHD/xwGOwCnzUG+lUuB9W8VaTIWu9sc=",
			created: "2023-12-22T16:33:04.515716464Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T602-00493322, ha sido aceptado",
			email: null,
			id: "do_SXjYDwAAAYySX4wD",
			agency_status: "0",
			digest: "URYjY2bh7F08VvD3KS75oQ==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T602-00493322",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "dc6238ac-cbe8-4b00-ae7d-733f3eb40d81",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "OBS",
			updated: "2023-12-22T16:31:05.981093119Z",
			time: "11:30:06",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T472",
			serial: 52375,
			size: 10442,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majhzX+6fgcrwZCI92G/9IMmovZDZCPytuJiLQs4/Akb8i+tO7ruUp57wSMoHD/xwGOwGjpg8LzTeIrpTWrSwgF7z8=",
			created: "2023-12-22T16:31:03.630646477Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T472-00052375, ha sido aceptado",
			email: null,
			id: "do_IakPKQAAAYySXbPO",
			agency_status: "0",
			digest: "UP8mPEaDiW8n6eiiTpIC0w==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T472-00052375",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "4ba9f024-afdc-46a7-932e-8e5252a3cf0b",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "OBS",
			updated: "2023-12-22T16:31:11.691673781Z",
			time: "11:30:33",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T472",
			serial: 52376,
			size: 138067,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh3EzbHNzTah/MjOgscTQ26/ZDZCPytuJiLQs4/Akb8i+XK960qRdM6wGaWp4cT50XyK1YyGoG5+xwqBgRmo+EGk=",
			created: "2023-12-22T16:31:03.616351166Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T472-00052376, ha sido aceptado",
			email: null,
			id: "do_X-jGpAAAAYySXbPA",
			agency_status: "0",
			digest: "Fm0G0TwpNEAqhCPvp4HAcA==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T472-00052376",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "1d9c1f17-52e1-4499-ae31-4bcdfa7c467f",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20506421781",
			status: "OBS",
			updated: "2023-12-22T16:31:05.813368916Z",
			time: "11:30:34",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T305",
			serial: 1799,
			size: 10972,
			filename: "",
			retries: 0,
			customer_name: "CORPORACIÓN RICO S.A.C.",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh06/kcBz6+NTpWN9HrWcG0FRucAOhs7uCW7lnXk5ZRyIWeRvXspQqfs7B2uOE+wfeOgH6boRwGiwnVjl0IYuqUs=",
			created: "2023-12-22T16:31:03.472263983Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T305-00001799, ha sido aceptado",
			email: null,
			id: "do_ULZlbwAAAYySXbMw",
			agency_status: "0",
			digest: "Q0BkwmwxzQtMcqPkaaBz8w==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
				"4190 - El valor ingresado como descripción de motivo de traslado no cumple con el estandar"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")</Nota>\r\n<Nota>4190 - El valor ingresado como descripción de motivo de traslado no cumple con el estandar",
			number: "T305-00001799",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "67082642-6b2f-4948-a458-aacde6cbd074",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "APR",
			updated: "2023-12-22T16:30:05.082553309Z",
			time: "11:28:54",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184382,
			size: 19708,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "",
			created: "2023-12-22T16:30:02.747137632Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: null,
			email: null,
			id: "do_GzNj7AAAAYySXMX7",
			agency_status: "0",
			digest: "ANudN3K3fQMihgs3+VFIaw==",
			agency_status_detail_list: [],
			map_geohash: null,
			agency_status_detail: "",
			number: "T870-00184382",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "47db9847-0813-494b-aaef-4b614f53da2f",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:30:09.466936574Z",
			time: "11:28:54",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184385,
			size: 26664,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh5Pwi0vGLbt1HU57rWw3Ymz3iUksZzJEgg+kZnjnpwvLiVZxAJaz4QKYFIhp/PwCXvcMPjerEc2rpvC8/+E/Uqw=",
			created: "2023-12-22T16:30:01.548423262Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T870-00184385, ha sido aceptado",
			email: null,
			id: "do_xOIJ0gAAAYySXMFM",
			agency_status: "0",
			digest: "7P5aX7Y7wDtjMZI3EtRPVQ==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T870-00184385",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "f1d4025e-0577-4d5f-a01f-6caf2fbcd967",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:30:09.407741866Z",
			time: "11:28:54",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184383,
			size: 29521,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh99EVZRlGCFM498uvwjWwi/3iUksZzJEgg+kZnjnpwvLLWyTWG4OKDh5IdFII9r0ovcMPjerEc2rpvC8/+E/Uqw=",
			created: "2023-12-22T16:30:01.535269626Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T870-00184383, ha sido aceptado",
			email: null,
			id: "do_arPBNgAAAYySXME_",
			agency_status: "0",
			digest: "Aqdm5D0tqgUyLLO1eGQiTw==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T870-00184383",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "0c99d162-894e-4c0b-91e1-07ceda74864e",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:30:03.959806450Z",
			time: "11:28:54",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184386,
			size: 26158,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh4u4FzS4eZWkAq5ylt4S9Cz3iUksZzJEgg+kZnjnpwvLl46SCLF6w1GEecvD5XjkwvcMPjerEc2rpvC8/+E/Uqw=",
			created: "2023-12-22T16:30:01.456201373Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T870-00184386, ha sido aceptado",
			email: null,
			id: "do_ojpl0QAAAYySXMDw",
			agency_status: "0",
			digest: "pWTW8JEgJFY1L4nHgzCzdg==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T870-00184386",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "9a40470a-283f-4a2a-81da-2c48c87348a3",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:30:03.770920610Z",
			time: "11:28:54",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184384,
			size: 27312,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh2m1BpQJMM5BfYWVYrOaZvT3iUksZzJEgg+kZnjnpwvLoDP1LEB52Jigdrr8k5qn6vcMPjerEc2rpvC8/+E/Uqw=",
			created: "2023-12-22T16:30:01.415879643Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T870-00184384, ha sido aceptado",
			email: null,
			id: "do_ztgxNQAAAYySXMDH",
			agency_status: "0",
			digest: "5PC/6G/fXLJitmHs1cfB3g==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T870-00184384",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "3d572fe6-bc46-4696-bffc-106349e3b378",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:30:09.095685726Z",
			time: "11:28:54",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184381,
			size: 29587,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh8h0KBdGIkLEDTusSZAwG6X3iUksZzJEgg+kZnjnpwvLaW4xGN9Md8gvvKApqFsR5fcMPjerEc2rpvC8/+E/Uqw=",
			created: "2023-12-22T16:30:01.139020001Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T870-00184381, ha sido aceptado",
			email: null,
			id: "do_LoW-SgAAAYySXL-z",
			agency_status: "0",
			digest: "gUNedzPsL42DFzJX+zji4g==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T870-00184381",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "2e553a1d-6a0e-4c5a-a679-a8853e64e7d3",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:29:02.729830850Z",
			time: "11:28:04",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184377,
			size: 40512,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh9GL67EDy7zjCdtPN6tl2jn3iUksZzJEgg+kZnjnpwvLK9Sy2kQRnZqWtvW5UHWSSPcMPjerEc2rpvC8/+E/Uqw=",
			created: "2023-12-22T16:29:00.423461107Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T870-00184377, ha sido aceptado",
			email: null,
			id: "do_vGsragAAAYySW9KH",
			agency_status: "0",
			digest: "6zV2SCYX7QkrpI7fqY+nWg==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T870-00184377",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "762e9179-e321-4ff4-9050-c96e0a27b16d",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:29:07.270923179Z",
			time: "11:28:04",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184375,
			size: 34763,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majhwn5K5ynE9QZwFlfyO9PamT3iUksZzJEgg+kZnjnpwvLz7wxl6cjKEZhxGdB12NKdfcMPjerEc2rpvC8/+E/Uqw=",
			created: "2023-12-22T16:28:59.456710640Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T870-00184375, ha sido aceptado",
			email: null,
			id: "do__lg_FAAAAYySW87A",
			agency_status: "0",
			digest: "M0KYjS9NTwsK3VxXjzVaAA==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T870-00184375",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "502fc655-da6b-40c0-8c28-4376f2dd0b2a",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:29:01.159612870Z",
			time: "11:28:05",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184379,
			size: 24974,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh0tAUpy/1StWmhKlA4yGiZL3iUksZzJEgg+kZnjnpwvL5C0UGUaJ3mD+2bhY41hCdfcMPjerEc2rpvC8/+E/Uqw=",
			created: "2023-12-22T16:28:58.826040639Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T870-00184379, ha sido aceptado",
			email: null,
			id: "do_K7WsZwAAAYySW8xK",
			agency_status: "0",
			digest: "f4jHGsdMKzjb5rm5ZYPMPQ==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T870-00184379",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "abd96fab-c939-49aa-b15f-b6a019d783dd",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:29:01.149629130Z",
			time: "11:28:04",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184376,
			size: 30676,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh6CWSWrWtosmYwkiUGWcV6L3iUksZzJEgg+kZnjnpwvLhvjfWPgyfCssEoUM7PjXlPcMPjerEc2rpvC8/+E/Uqw=",
			created: "2023-12-22T16:28:58.825939525Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T870-00184376, ha sido aceptado",
			email: null,
			id: "do_hoCZQQAAAYySW8xJ",
			agency_status: "0",
			digest: "jVKTII9+6uScgSo2AXkHqQ==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T870-00184376",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "da0b3a11-f6e2-41f1-859b-ee1476dafb51",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:29:05.891452959Z",
			time: "11:28:04",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184374,
			size: 31323,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh/dzcdU/A1fA+9APsDdJL6D3iUksZzJEgg+kZnjnpwvLzokxRSLdaaou1WGyKvPOcPcMPjerEc2rpvC8/+E/Uqw=",
			created: "2023-12-22T16:28:58.594815259Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T870-00184374, ha sido aceptado",
			email: null,
			id: "do_QhOzdAAAAYySW8ti",
			agency_status: "0",
			digest: "/Z82SdNpppXijQVLDODOWg==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T870-00184374",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "e1692f52-cc2d-456d-94ed-e482ea0eb0e0",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:29:00.906991236Z",
			time: "11:28:05",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184380,
			size: 35839,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh+7kBP5fp9UCvpLt5HkMlFf3iUksZzJEgg+kZnjnpwvLrOj1HeFlOKZSdfqVWgSLvfcMPjerEc2rpvC8/+E/Uqw=",
			created: "2023-12-22T16:28:58.580084988Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T870-00184380, ha sido aceptado",
			email: null,
			id: "do_dNeFPwAAAYySW8tU",
			agency_status: "0",
			digest: "ZF1NEZQWwRnfJ3cc8KViJg==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T870-00184380",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "4480436f-0a86-47e1-89f6-0f98c397a2bd",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:29:06.348964677Z",
			time: "11:28:05",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T870",
			serial: 184378,
			size: 43951,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh1okpJCmaSPD9lq8IHNpYCj3iUksZzJEgg+kZnjnpwvL3f67K809JRCL96ADS/AqC/cMPjerEc2rpvC8/+E/Uqw=",
			created: "2023-12-22T16:28:58.570935134Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T870-00184378, ha sido aceptado",
			email: null,
			id: "do_uJKMJwAAAYySW8tK",
			agency_status: "0",
			digest: "q9Mg0JBfcWh7+5Lhh3gY+w==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T870-00184378",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "8de490c1-6010-4223-bd6e-cd2664630c4e",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "OBS",
			updated: "2023-12-22T16:28:55.269027746Z",
			time: "11:28:36",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T602",
			serial: 493321,
			size: 10989,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh4J8U3PmxgfzghCXca3bBoHZDZCPytuJiLQs4/Akb8i+/13UFeEtYxmEKRJPaMkB+vBEm5IzrOI3MHiz4n4XpiM=",
			created: "2023-12-22T16:28:52.953530563Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T602-00493321, ha sido aceptado",
			email: null,
			id: "do_-xmhIgAAAYySW7VZ",
			agency_status: "0",
			digest: "DvOcKwgq8Mv9Jr6jQHhffw==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T602-00493321",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "bef749a9-2f92-40ac-8294-a84060fc216e",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "OBS",
			updated: "2023-12-22T16:27:53.458167392Z",
			time: "11:27:16",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T602",
			serial: 493320,
			size: 10316,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh+LSlOp9lBGGS+3JrBR9xRTZDZCPytuJiLQs4/Akb8i+tO7ruUp57wSMoHD/xwGOwCnzUG+lUuB9W8VaTIWu9sc=",
			created: "2023-12-22T16:27:51.261283762Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T602-00493320, ha sido aceptado",
			email: null,
			id: "do_jDPW8wAAAYySWsRd",
			agency_status: "0",
			digest: "itLUhRJLahsN+vcdUPQy4A==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T602-00493320",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "508435bf-8426-4580-b88b-e4b7c7c813aa",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20492092313",
			status: "OBS",
			updated: "2023-12-22T16:26:55.949134630Z",
			time: "11:25:34",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T602",
			serial: 493318,
			size: 218508,
			filename: "",
			retries: 0,
			customer_name: "MAKRO SUPERMAYORISTA S.A.",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh3rUYfBmMPDPZkV/lrKqZgfbkIlqVUO47Uid1lXc1oJ/EBgiJk9MfsRmqxo84D4v3fBEm5IzrOI3MHiz4n4XpiM=",
			created: "2023-12-22T16:26:47.808080424Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T602-00493318, ha sido aceptado",
			email: null,
			id: "do_-22bUAAAAYySWcyA",
			agency_status: "0",
			digest: "TkQvv8y5tEXdjzTtF1ThuQ==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T602-00493318",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "4fd8377b-3a1c-4ff5-80a8-76c0f81776c4",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "OBS",
			updated: "2023-12-22T16:26:52.583428085Z",
			time: "11:25:32",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T602",
			serial: 493317,
			size: 174083,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majhzorcgzy7wNt+BhxGg0c1SDZDZCPytuJiLQs4/Akb8i+iIIhbUpfAxKzGr+WnYQum955o2L7ERMa1R0Q+1nnl3Y=",
			created: "2023-12-22T16:26:44.732631523Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T602-00493317, ha sido aceptado",
			email: null,
			id: "do_-xUVDQAAAYySWcB8",
			agency_status: "0",
			digest: "P7lR1u1+0Gn/wNQ0tTUAJQ==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T602-00493317",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "f668077a-64fb-4851-8d3a-bc84f6b01670",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20348735692",
			status: "OBS",
			updated: "2023-12-22T16:26:46.990660778Z",
			time: "11:25:36",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T166",
			serial: 4691,
			size: 10480,
			filename: "",
			retries: 0,
			customer_name: "PANIFICADORA BIMBO DEL PERU S.A.",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh1Kbcq2bBziZVtQcgiD1RKnEVBYcPXn00rlWy1yLqcXnElfgxcOYQqBLeNT/w2Df3T7oAxZqMdQptBCCcl+pZjU=",
			created: "2023-12-22T16:26:44.661108722Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T166-00004691, ha sido aceptado",
			email: null,
			id: "do_CclkmQAAAYySWcA1",
			agency_status: "0",
			digest: "P/i0FJIVIF7TRZc3HDcO2A==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
				"4190 - El valor ingresado como descripción de motivo de traslado no cumple con el estandar"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")</Nota>\r\n<Nota>4190 - El valor ingresado como descripción de motivo de traslado no cumple con el estandar",
			number: "T166-00004691",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "11f35fbd-ba88-4b6b-8989-718fdce71ba5",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20492092313",
			status: "OBS",
			updated: "2023-12-22T16:26:42.911627917Z",
			time: "11:25:42",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T602",
			serial: 493319,
			size: 10415,
			filename: "",
			retries: 0,
			customer_name: "MAKRO SUPERMAYORISTA S.A.",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majhyPJvjbf1HChW6cQFPOTp+fbkIlqVUO47Uid1lXc1oJ/tO7ruUp57wSMoHD/xwGOwPBEm5IzrOI3MHiz4n4XpiM=",
			created: "2023-12-22T16:26:40.616365850Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T602-00493319, ha sido aceptado",
			email: null,
			id: "do_2cf05gAAAYySWbBo",
			agency_status: "0",
			digest: "d70wHT5nDPrFoEw3zTjRvQ==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T602-00493319",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "09337c0a-49b0-49f7-b685-ffc538594a38",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20100070970",
			status: "OBS",
			updated: "2023-12-22T16:25:45.923821088Z",
			time: "11:24:43",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T469",
			serial: 153282,
			size: 10805,
			filename: "",
			retries: 0,
			customer_name: "SUPERMERCADOS PERUANOS SA",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh/RcSaz4jxxk5++Lst2ozcRaDS8l6z4uPYU+KJLvZXxTpfQhiGn2Ast89HTs++gwd/QDIdTx5gApgAkBN1b7W6U=",
			created: "2023-12-22T16:25:43.680391824Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T469-00153282, ha sido aceptado",
			email: null,
			id: "do_INzy7QAAAYySWNIA",
			agency_status: "0",
			digest: "+T86dn2GNjLPOLaRpJWn2Q==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T469-00153282",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "2156a3ea-8089-4627-865d-876170344d28",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "OBS",
			updated: "2023-12-22T16:23:30.227564468Z",
			time: "11:22:45",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T602",
			serial: 493316,
			size: 10418,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majhwd2ITiFvsLbXjmezXDxSxrZDZCPytuJiLQs4/Akb8i+tO7ruUp57wSMoHD/xwGOwCnzUG+lUuB9W8VaTIWu9sc=",
			created: "2023-12-22T16:23:27.987998824Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T602-00493316, ha sido aceptado",
			email: null,
			id: "do_-VP0vgAAAYySVr_z",
			agency_status: "0",
			digest: "foJ7M7HSJMlzZ9n3GDJVmg==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T602-00493316",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "c0a9db95-bf31-439b-bff0-95debc8eae16",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608300393",
			status: "OBS",
			updated: "2023-12-22T16:22:40.879195766Z",
			time: "11:21:21",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T602",
			serial: 493314,
			size: 184723,
			filename: "",
			retries: 0,
			customer_name: "COMPANIA FOOD RETAIL SAC",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majhxulqLmGK/rYhfiqBmgoqIfZDZCPytuJiLQs4/Akb8i+DUK7GI34xgadi/J00YEl/a/N5BlBbIlZfVMBXwXlSDQ=",
			created: "2023-12-22T16:22:32.731952054Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T602-00493314, ha sido aceptado",
			email: null,
			id: "do__WYGBAAAAYySVegb",
			agency_status: "0",
			digest: "lvHyujCEtuIvMSuBSvD4KQ==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T602-00493314",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "45c5ad0b-87fd-49d0-8cf2-c4174fcec3f3",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:22:34.922684900Z",
			time: "11:21:19",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T834",
			serial: 199138,
			size: 45646,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh6IBsSate4D7hsPiPY4Evm73iUksZzJEgg+kZnjnpwvLaQhKQGvWgdYPJq52C8/sbALfAu86bzR7FUmg7xJCuSU=",
			created: "2023-12-22T16:22:32.489651207Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T834-00199138, ha sido aceptado",
			email: null,
			id: "do_Cp6i5AAAAYySVecp",
			agency_status: "0",
			digest: "zXw7XuzJOtEKSIXClCFogA==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T834-00199138",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "637b93b5-9dfc-4212-89f4-e05a9ef94706",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		},
		{
			amount_tax: null,
			customer_tax_id: "20608280333",
			status: "OBS",
			updated: "2023-12-22T16:22:33.793358222Z",
			time: "11:21:19",
			amount_total: "0",
			acc_id: "acc_DoNRsoG1aqoh4f3yOz",
			tax_id: "20100070970",
			series: "T834",
			serial: 199139,
			size: 34234,
			filename: "",
			retries: 0,
			customer_name: "CHD S.A.C",
			amount_excempt: null,
			params: null,
			amount_discount: null,
			amount_net: "0",
			agency_hash: "https://e-factura.sunat.gob.pe/v1/contribuyente/gre/comprobantes/descargaqr?hashqr=CPIXeEd8eyHvmkFe2majh/LeBTlJyl9cEzP5MJITSCr3iUksZzJEgg+kZnjnpwvLaT6MFVKC4YlvK6uW4Ot8qwLfAu86bzR7FUmg7xJCuSU=",
			created: "2023-12-22T16:22:31.323013661Z",
			test: false,
			agency: "SUNAT",
			branch_id: null,
			idx_text: "",
			agency_status_info: "El Comprobante  numero T834-00199139, ha sido aceptado",
			email: null,
			id: "do_atf4EwAAAYySVeKb",
			agency_status: "0",
			digest: "Ky3QQPQd817suiMsHvhy4Q==",
			agency_status_detail_list: [
				"4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")"
			],
			map_geohash: null,
			agency_status_detail: "4391 - El Numero de Registro MTC del transportista no existe o no contiene informacion : 4391INFO: errorCode 4391 (nodo: \"/\" valor: \"\")",
			number: "T834-00199139",
			pos_id: null,
			custom_id: "",
			type: "PE09",
			agency_id: "93667cf0-2ee1-4cd5-b2da-2a2190c01aab",
			agency_voucher: null,
			country: "PE",
			template: "GRER.jasper",
			amount_subtotal: null,
			date: "2023-12-22"
		}
	]