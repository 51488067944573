<template>
  <v-card class="pa-0">
    <v-card-title class="pa-0">
      <v-toolbar class="px-4" color="transparent" flat height="48">
        <v-toolbar-title class="body-1 secondary--text font-weight-bold">Información del documento</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-row align="center" justify="center" class="mr-0">
            <v-btn @click="$emit('close')" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
          </v-row>
        </v-toolbar-items>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text class="pa-0">
      <v-list class="px-4 v-list-form py-6 transparent">
        <v-list-item class="py-1">
          <v-list-item-title>Receptor</v-list-item-title>
          <v-list-item-subtitle>{{ items.taxpayer.name }}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Tipo de documento</v-list-item-title>
          <v-list-item-subtitle class="py-1">{{ items.document_type }}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Serie - Correlativo</v-list-item-title>
          <v-list-item-subtitle class="py-1">{{ items.serie }}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Creado el</v-list-item-title>
          <v-list-item-subtitle class="py-1">{{ items.date }}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Total</v-list-item-title>
          <v-list-item-subtitle class="py-1">630.16 PEN</v-list-item-subtitle>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-col class="px-5 my-4 pb-6">
        <span class="d-block font-weight-bold">Recibidos</span>
        <span class="d-block my-2 body-2 fontDraw--text">ejemplomail@empresa.com</span>

        <span class="d-block font-weight-bold mt-5">Abiertos</span>
        <span class="d-block my-2 body-2 fontDraw--text">ejemplomail@empresa.com</span>
        <span class="d-block my-2 body-2 fontDraw--text">ejemplomail@empresa.com</span>

        <span class="d-block font-weight-bold mt-5">Rebotado - mail no existe</span>
        <span class="d-block my-2 body-2 fontDraw--text">ejemplomail@empresa.com</span>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    items: {
      type: Object,
      default: () => { }
    }
  },
  data: () => ({
    errorList: {
      PSE: 'Documento no encontrado',
      OSE: 'El comprobante fue registrado previamente con otros datos',
      SUNAT: 'Documento aún no se encuentra en SUNAT'
    }
  })
}
</script>